import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import TVAnimation from "../Animations/TVAnimation";

const Content = styled.div`
    text-align: center;
`

class PhotosBlock extends Component {
    constructor(props) {

        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            modalOpen: false,
        }

    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    render() {
        const {isOpen, photoIndex} = this.state;

        let images = [];

        const showGallery = this.props.data.tour.edges.map((item, i) => {
            let obj = {}
            obj.src = item.node.image.childImageSharp.gatsbyImageData.images.fallback.src
            obj.caption = item.node.caption
            images.push(obj)
            return (
                <Col xs={12} sm={6} lg={4} key={i} className="py-3">
                    <div
                        tabIndex={i} role="button" aria-pressed="false"
                        onClick={() => this.setState({photoIndex: i, isOpen: true})}
                        onKeyDown={() => this.setState({photoIndex: i, isOpen: true})}
                        className="w-100"
                    >
                        <GatsbyImage image={getImage(item.node.thumb)}
                            alt=""
                            onClick={() => this.setState({photoIndex: i, isOpen: true})}
                            className="img-fluid"
                        />
                    </div>
                </Col>
            )
        })

        return (
            <Content className="py-3 py-md-4">
                <h2 className="h1 mb-1 mb-sm-2 mt-md-n2">Photos</h2>

                <Container>
                    <Row className="justify-content-center">
                        {showGallery}
                    </Row>
                    {this.props.city === 'new-york' &&
                        <p className="text--small text-center">(Photos of the London Cast)</p>
                    }
                    <p className="text--small text-center">Production photography by Sean Ebsworth Barnes and Matt Crockett</p>
                </Container>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].src}
                        nextSrc={images[(photoIndex + 1) % images.length].src}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                        imageCaption={images[photoIndex].caption}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </Content>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
               tour : allGalleryUsTourJson {
                    edges {
                        node {
                            id
                            thumb {
                                childImageSharp {
                                    gatsbyImageData(width: 330)
                                }
                            }
                            image {
                                childImageSharp {
                                     gatsbyImageData(width: 1920)
                                }
                            }
                            caption
                        }
                    }
                }
            }`
        }
        render={data => (
            <PhotosBlock data={data} {...props}/>
        )}
    />
)
// 1 10 11