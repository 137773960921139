import React, {Component} from "react"
import Layout from "components/Layout/LayoutUSTour"
import PageHeader from "components/shared/PageHeader"
import CameraAnimation from "../../components/Animations/CameraAnimation"
import VideoBlock from "../../components/VideoMusicPhotoPage/VideoBlock";
import AudioBlock from "../../components/VideoMusicPhotoPage/AudioBlock";
import UsTourPhotosBlock from "components/us-tour/UsTourPhotoBlock.js";
import GreatScottBlock from "../../components/HomePage/GreatScottBlock";

class MusicPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			musicStopped: false,
			videoStopped: false
		}

		this.stopMusic = this.stopMusic.bind(this);
		this.stopVideo = this.stopVideo.bind(this);
	}

	stopMusic = (musicStopped) => {
		this.setState({
			musicStopped
		})
	}

	stopVideo = (videoStopped) => {
		this.setState({
			videoStopped
		})
	}

	componentDidUpdate(prevProps,prevState) {
		if (prevState.musicStopped !== this.state.musicStopped) {
			this.setState({
				musicStopped: this.state.musicStopped
			})
		}
	}

	render() {
		return (
			<Layout
				title="Music, Video & Photos | North American Tour | Back to the Future the Musical"
				description="Music, Videos and Photos | The official website for Back to the Future The Musical - North American Tour. Landing in a city near you from Summer 2024. "
				booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
			>
				<PageHeader city="us-tour" title="Music, Video & Photos" />
				<AudioBlock
					stopMusic={this.stopMusic}
					stopVideo={this.stopVideo}
					videoStopped={this.state.videoStopped}
					musicStopped={this.state.musicStopped}
				/>
				<VideoBlock
					stopMusic={this.stopMusic}
					stopVideo={this.stopVideo}
					videoStopped={this.state.videoStopped}
					musicStopped={this.state.musicStopped}
					city="new-york"
				/>
				<CameraAnimation/>
				<UsTourPhotosBlock/>
			</Layout>
		)
	}
}

export default MusicPage